import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";
import SleepyNewsAnchor from "../../components/SleepyNewsAnchor";

const NewsPage = () => (
	<Layout>
		<Seo title="December 2021" />
		<NewsHeader />
		<h2>December 2021</h2>

		<h3 className="blogdate">
			Thursday, December 23, 2021
		</h3>

		<h4 className="blogitemtitle">
			<SleepyNewsAnchor num="1">New Website Nearly Finished</SleepyNewsAnchor>
		</h4>
		<div className="blogitembody">
			<p>
				At the moment, I am migrating my old website into a new, modern format that will be easy for me to update.
				Perhaps you noticed that I wasn't updating my old website anymore? Well, those days are over!
				I'll now be able to post shows, news, new releases, etc., as they happen.
			</p>
			<p>
				I'm not really playing any shows at the moment, but I do a Friday Coffee Break Song live stream on Instagram every Friday;
				you can find the time for your time zone by visiting my <Link to="/l/bio/">#linkinbio</Link>.
				I am also releasing new singles, so do check out my <Link to="/collab/">Collab</Link> and my <Link to="/music/">Music</Link> pages.
			</p>
		</div>

		<p>
		<Link to="/news/">Go back to the news index page</Link>
		</p>
	</Layout>
);

export default NewsPage;